@media print {
  .btn {
    display: none;
  }

  select {
    border: none !important;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
  }
}
